<template>
  <landing-card
    :title="lngcontent.title"
    icon-type="el-icon-fa-microphone"
    :description="lngcontent.description"
    :nextLink="getNextPath"
    :viewButton="this.step === 'RECORD_SUCCESS'"
    :checkRTL="checkRTL"
  >
    <div class="get-mic">
      <div
        v-if="
          this.step === 'INITIAL' ||
            this.step === 'PERMISSION_ERROR'
        "
      >
        <!-- eslint-disable-next-line prettier/prettier -->
        <el-button
          type="success"
          class="btn-next btn-get-mic"
          @click="this.requestPermission"
          round
        >
          <!-- eslint-disable-next-line prettier/prettier -->
          {{lngcontent.getmicrophone}}
        </el-button>
        <div v-if="hasError">
          <!-- eslint-disable-next-line prettier/prettier -->
          <el-alert show-icon center :closable="false" type="error">
            <!-- eslint-disable-next-line prettier/prettier -->
            {{ errors[0] }}
          </el-alert>
        </div>
      </div>
      <div v-if="this.step === 'PERMISSION_SUCCESS'">
        <!-- eslint-disable-next-line prettier/prettier -->
        <el-button
          type="success"
          class="btn-next"
          @click="this.recordDemo"
          round
          >{{ lngcontent.startrecord }}</el-button
        >
      </div>
      <div
        id="record-check"
        v-if="this.step === 'RECORD_STARTED'"
      >
        <!-- -->
        <!-- eslint-disable-next-line prettier/prettier -->
        <el-button
          type="success"
          class="btn-next"
          @click="this.stop"
          round
          >{{ lngcontent.stoprecord }}</el-button
        >
        <div id="loading" class="text-center">
          <i v-loading="true"></i>
          <!-- <i class="el-icon-loading"></i> -->
          <span id="recording">{{
            lngcontent.recording
          }}</span>
        </div>
      </div>
      <div v-if="this.step === 'RECORD_STOP'">
        <!-- eslint-disable-next-line prettier/prettier -->
        <el-button type="success" class="btn-next btn-loading" round>{{lngcontent.loading}}</el-button>
      </div>
      <div v-if="this.step === 'RECORD_SUCCESS'">
        <!-- eslint-disable-next-line prettier/prettier -->
        <audio id="ses" controls :src="this.audioUrl"></audio>
        <el-button
          class="btn-next"
          type="primary"
          round
          @click="reset"
        >
          {{ lngcontent.tryagain }}
          <i
            class="text-contrast raw-icon el-icon-recycle"
          ></i
        ></el-button>
      </div>
    </div>
    <ul class="desc-list">
      <li :class="checkTextRight" v-for="i in 5" :key="i">
        <app-i18n-wl
          :code="'entities.audiocheck.desclist.' + [i - 1]" :lang="lang"
        ></app-i18n-wl>
      </li>
    </ul>
  </landing-card>
</template>

<script>
import { i18nWL } from '@/i18n';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'online-exam-check',
  props: ['id', 'lang'],
  data() {
    return {
      lngcontent: {
        title: null,
        description: null,
        getmicrophone: null,
        startrecord: null,
        stoprecord: null,
        recording: null,
        loading: null,
        tryagain: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      step: 'onlineExam/recorder/step',
      audioUrl: 'onlineExam/recorder/audioUrl',
      hasError: 'onlineExam/recorder/hasError',
      errors: 'onlineExam/recorder/errors',
    }),
    getNextPath() {
      return `/online-exam/${this.id}/1`;
    },
    checkRTL() {
      //burda exam cekilmedigi icin lokal checkRTL kullanilir
      return this.lang === 'ar' ? ' rtl ' : '';
    },
    checkTextRight() {
      //burda exam cekilmedigi icin lokal checkRTL kullanilir
      return this.lang === 'ar' ? ' text-right ' : '';
    },
  },
  mounted() {
    for (let pr in this.lngcontent) {
      this.lngcontent[pr] = i18nWL(
        'entities.audiocheck.' + pr, this.lang
      );
    }
    this.requestPermission();
  },
  methods: {
    ...mapActions({
      recordDemo: 'onlineExam/recorder/recordDemo',
      stop: 'onlineExam/recorder/stop',
      reset: 'onlineExam/recorder/reset',
      requestPermission:
        'onlineExam/recorder/requestPermission',
    }),
  },
};
</script>

<style>
.el-icon-fa-microphone:before {
  font-size: 1.25rem;
}
audio#ses {
  display: block;
  margin: auto;
}
ul.desc-list > li.text-right {
  text-align: right;
}
</style>

<style lang="stylus" scoped>
.get-mic {
  > div {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  border-bottom: 1px solid #efefef;
  margin-bottom: 1rem;

  button {
    margin: auto;
  }

  .btn-loading {
    margin: 2rem auto;
  }

  .btn-get-mic {
    margin: 0 auto;
  }
}

.error-message {
  margin: 0rem 0rem 2rem 0rem;
}

#record-check {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#record-check >span {
  margin-top: 1.5rem;
}

#loading {
  margin: 0 auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#recording {
  margin-top: 1.5rem;
}
</style>
